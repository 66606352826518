// UserStatusIndicator.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import WithIntersectionObserver from '../component/WithIntersectionObserver.jsx';

import LiveStreamBadge from '../container/LiveStreamBadge.js';

import {
  LIVESTREAM_TYPE_DOT,
  ONLINE_TYPE_DOT,
  ONLINE_TYPE_PROFILE_DOT,
  ONLINE_TYPE_PROFILE_MOBILE_DOT,
  ONLINE_TYPE_BIGGER_DOT,
  ONLINE_TYPE_BIGGER_DOT_WITH_BLACK_BORDER,
} from '../resource/userStatusIndicatorConstants.js';
import { EMPTY_OBJECT, NULL_FUNCTION } from '../resource/defaults.js';
import { getHomePageConfig } from '../resource/homeUtils.js';
import { color } from '../style/variables.js';

export class UserStatusIndicator extends React.PureComponent {
  state = {
    isIntersecting: false,
  };
  getUserLatestData = () => {
    const {
      userId,
      location,
      privateUserChannel,
      addInViewportUserId,
      removeInViewportUserId,
    } = this.props;
    const { isIntersecting } = this.state;
    const { pathname } = location;
    const { shouldUseRetainedEvents } = getHomePageConfig({ pathname });
    const isPrivateUserSubscribed = privateUserChannel.subscribed;

    if (!userId) return;
    if (isPrivateUserSubscribed) {
      return removeInViewportUserId({ id: userId });
    }
    if (shouldUseRetainedEvents) {
      if (isIntersecting) return addInViewportUserId({ id: userId });
      return removeInViewportUserId({ id: userId });
    }
    return this.subscribeOnlineStatus();
  };
  subscribeOnlineStatus = () => {
    const {
      userId,
      subscribeOnlineStatus,
      shouldShowLiveStream,
      shouldShowOnline,
      shouldShowOffline,
    } = this.props;

    if (!userId) return;

    const shouldSubscribeChannel =
      shouldShowLiveStream || shouldShowOnline || shouldShowOffline;
    if (shouldSubscribeChannel) subscribeOnlineStatus({ userId });
  };
  handleIntersection = ({ isIntersecting }) => {
    this.setState({ isIntersecting });
  };
  componentDidMount() {
    this.getUserLatestData();
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      userId: prevUserId,
      privateUserChannel: prevPrivateUserChannel,
      location: prevLocation,
    } = prevProps;
    const { userId, privateUserChannel, location } = this.props;
    const { isIntersecting: prevIsIntersecting } = prevState;
    const { isIntersecting } = this.state;

    if (
      userId !== prevUserId ||
      isIntersecting !== prevIsIntersecting ||
      privateUserChannel.subscribed !== prevPrivateUserChannel.subscribed ||
      location.pathname !== prevLocation.pathname
    ) {
      this.getUserLatestData();
    }
  }
  componentWillUnmount() {
    const { userId, removeInViewportUserId } = this.props;
    removeInViewportUserId({ id: userId });
  }
  render() {
    const {
      userId,
      isLiveStreaming,
      isOnline,
      shouldShowLiveStream,
      liveStreamType,
      livestreamSize,
      onlineType,
      shouldShowOnline,
      shouldShowOffline,
    } = this.props;

    let Component = <div />;

    if (userId) {
      if (isLiveStreaming && shouldShowLiveStream) {
        Component = (
          <LiveStreamBadge
            streamId={userId}
            userId={userId}
            type={liveStreamType}
            size={livestreamSize}
          />
        );
      } else if (shouldShowOnline && isOnline) {
        Component = <OnlineIndicator type={onlineType} />;
      } else if (shouldShowOffline && !isOnline) {
        Component = <OfflineIndicator type={onlineType} />;
      }
    }

    return (
      <WithIntersectionObserver
        threshold={0.25}
        onChange={this.handleIntersection}
        shouldKeepObserve
      >
        {() => Component}
      </WithIntersectionObserver>
    );
  }
}

UserStatusIndicator.propTypes = {
  userId: PropTypes.string,
  liveStreamType: PropTypes.string,
  livestreamSize: PropTypes.number,
  onlineType: PropTypes.string,
  isLiveStreaming: PropTypes.bool,
  isOnline: PropTypes.bool,
  shouldShowLiveStream: PropTypes.bool,
  shouldShowOnline: PropTypes.bool,
  shouldShowOffline: PropTypes.bool,
  location: PropTypes.object,
  privateUserChannel: PropTypes.object,
  subscribeOnlineStatus: PropTypes.func,
  addInViewportUserId: PropTypes.func,
  removeInViewportUserId: PropTypes.func,
};

UserStatusIndicator.defaultProps = {
  userId: null,
  liveStreamType: LIVESTREAM_TYPE_DOT,
  livestreamSize: 18,
  onlineType: ONLINE_TYPE_DOT,
  isLiveStreaming: null,
  isOnline: false,
  shouldShowLiveStream: false,
  shouldShowOnline: false,
  shouldShowOffline: false,
  location: EMPTY_OBJECT,
  privateUserChannel: EMPTY_OBJECT,
  subscribeOnlineStatus: NULL_FUNCTION,
  addInViewportUserId: NULL_FUNCTION,
  removeInViewportUserId: NULL_FUNCTION,
};

const getDimension = type => {
  switch (type) {
    case ONLINE_TYPE_PROFILE_DOT:
      return '20px';
    case ONLINE_TYPE_PROFILE_MOBILE_DOT:
      return '16px';
    case ONLINE_TYPE_BIGGER_DOT:
    case ONLINE_TYPE_BIGGER_DOT_WITH_BLACK_BORDER:
      return '12px';
    default:
      return '8px';
  }
};

const getMargin = type => {
  switch (type) {
    case ONLINE_TYPE_BIGGER_DOT:
    case ONLINE_TYPE_BIGGER_DOT_WITH_BLACK_BORDER:
      return '2px';
    case ONLINE_TYPE_PROFILE_DOT:
      return '4px';
    default:
      return '';
  }
};

const OnlineIndicator = styled.div.attrs(({ type }) => ({
  style: {
    width: getDimension(type),
    height: getDimension(type),
    margin: getMargin(type),
  },
}))`
  border-radius: 50%;
  background-color: ${color.lime};
  ${({ type }) => {
    if (type === ONLINE_TYPE_BIGGER_DOT_WITH_BLACK_BORDER) {
      return css`
        border: 1px solid ${color.grey['900']};
      `;
    }
  }}
`;

const OfflineIndicator = styled.div.attrs(({ type }) => ({
  style: {
    width: type === ONLINE_TYPE_BIGGER_DOT ? '12px' : '8px',
    height: type === ONLINE_TYPE_BIGGER_DOT ? '12px' : '8px',
    margin: type === ONLINE_TYPE_BIGGER_DOT ? '' : '2px',
  },
}))`
  border-radius: 50%;
  background-color: ${color.grey['300']};
`;

export default UserStatusIndicator;
